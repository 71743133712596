const FetchLibrary = (accessToken, libraryId) => {
  return FetchUserData(accessToken, "libraries", libraryId);
};

const FetchBar = (accessToken, barId) => {
  return FetchUserData(accessToken, "bars", barId);
};

const FetchFavorites = (accessToken, favoritesId) => {
  return FetchUserData(accessToken, "favorites", favoritesId);
};

const FetchUserData = async (accessToken, resource, id) => {
  const url = `${process.env.REACT_APP_MIXIRS_API}/api/${resource}/${id}`;
  const options = {
    method: "get",
    withCredentials: true,
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }),
  };
  const response = await fetch(url, options)
    .then((r) => r.json())
    .then(
      async (result) => {
        console.log(`got data from ${resource}`);
        return result;
      },
      (error) => {
        console.error(error);
        return error;
      }
    );

  return response;
};

export { FetchLibrary, FetchBar, FetchFavorites, FetchUserData };
