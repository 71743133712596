const SetBarItem = async (ingredientId, action, userContent) => {
  const url = `${process.env.REACT_APP_MIXIRS_API}/api/bars/${userContent.bar}`;
  const body = {
    action: action,
    ingredientId: ingredientId,
  };

  const request = {
    method: "patch",
    withCredentials: true,
    headers: new Headers({
      Authorization: `Bearer ${userContent.accessToken}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(body),
  };

  const response = await fetch(url, request);
  console.debug("SetBarItem", response);

  return response;
};

export default SetBarItem;
