const Contact = () => {
  return (
    <div className="container">
      <h4 className="display-4">Contact</h4>
      <p>
        If you need help or have a problem - drop me a line - mixirs.info [at]
        gmail.com
      </p>
    </div>
  );
};

export default Contact;
