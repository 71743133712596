import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DrinkCard from "../drink-card";
import { useEffect, useState } from "react";

const DrinkGrid = ({ drinks, drinkBookmarks }) => {
  const [bookmarks, setBookmarks] = useState(new Map());

  useEffect(() => {
    // console.log("useEffect - drinkGrid", drinkBookmarks);
    if (drinkBookmarks?.length) {
      const tmp = new Map(
        drinks.map((drink) => [drink.id, drinkBookmarks.includes(drink.id)])
      );
      // console.log("drink grid favorites", tmp);
      setBookmarks(tmp);
    }
  }, [drinks, drinkBookmarks]);
  
  if (!drinks) return null;

  return (
    <Row xs={1} md={3}>
      {drinks.map((itm) => {
        return (
          <Col key={itm.id} className="py-2">
            <DrinkCard
              drink={itm}
              showBookmark={true}
              isBookmarked={bookmarks.get(itm.id) ?? false}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default DrinkGrid;
