import SearchParamKeys from "../common/SearchParamKeys";

const BuildDrinkSearchParams = (searchBody, viewMode) => {
  console.debug("BuildDrinkSearchParams", searchBody);

  let p = new URLSearchParams();
  if (searchBody.pageNumber > 0) {
    p.append(SearchParamKeys.page_number, searchBody.pageNumber);
  }
  if (searchBody.searchText) {
    p.append(SearchParamKeys.search_text, searchBody.searchText);
  }
  if (searchBody?.maxDrinkIngredients > 0) {
    p.append(SearchParamKeys.max_ingredient, searchBody.maxDrinkIngredients);
  }
  if (searchBody.orderBy) {
    p.append(SearchParamKeys.order_by, searchBody.orderBy);
  }
  if (viewMode) {
    p.append(SearchParamKeys.view_mode, viewMode);
  }

  searchBody.sourceFilter.forEach((x) =>
    p.append(SearchParamKeys.source_facet, x)
  );
  searchBody.baseSpirits.forEach((x) =>
    p.append(SearchParamKeys.base_spirit_facet, x)
  );

  return p;
};

export default BuildDrinkSearchParams;
