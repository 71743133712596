import Form from "react-bootstrap/Form";

const DrinkSelectionTableRow = ({ drink, onSelection, status }) => {  

  return (
    <tr>
      <td>
        <Form.Check type="switch" onBlur={() => onSelection(drink.id)} />
      </td>
      <td>{drink.name}</td>
      <td>{drink.src}</td>
      <td>{status}</td>
    </tr>
  );
};

export default DrinkSelectionTableRow;
