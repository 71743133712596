import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const DrinkIngredientRow = ({ ingredient, onChange, onDelete }) => {
  return (
    <tr>
      <td>{ingredient.ingredientName}</td>
      <td>
        <Form.Control
          type="text"
          defaultValue={ingredient.modification}
          onBlur={(e) =>
            onChange({ ...ingredient, modification: e.target.value })
          }
        />
      </td>
      <td>
        <div>
          <Form.Check
            type="checkbox"
            defaultChecked={ingredient.optional}
            onBlur={(e) =>
              onChange({ ...ingredient, optional: e.target.checked })
            }
          />
        </div>
      </td>
      <td>
        <Form.Control
          type="text"
          defaultValue={ingredient.quantity}
          onBlur={(e) => onChange({ ...ingredient, quantity: e.target.value })}
        />
      </td>
      <td>
        <Form.Control
          as="select"
          defaultValue={ingredient.units}
          onBlur={(e) => onChange({ ...ingredient, units: e.target.value })}
        >
          <option>oz</option>
          <option>dash</option>
          <option>barspoon</option>
          <option>piece(s)</option>
          <option>Top Up</option>
        </Form.Control>
      </td>
      <td>
        <Button variant="outline-danger" onClick={onDelete}>
          Remove
        </Button>
      </td>
    </tr>
  );
};

export default DrinkIngredientRow;
