import Home from "../home";
import DrinkDetail from "../drink-details";
import { Routes, Route } from "react-router-dom";
import Profile from "../profile";
import IngredientDetail from "../ingredient-details";
import IngredientsIndex from "../ingredients/index";
import DrinksIndex from "../drinks";
import Privacy from "../privacy";
import Contact from "../contact";
import Search from "../search";
import Admin from "../admin";
import CreateDrink from "../admin/CreateDrink";
import EditDrink from "../admin/EditDrink";
import ErrorPage from "../error-page";
import CreateIngredient from "../admin/CreateIngredient";
import EditIngredient from "../admin/EditIngredient";
import ReplaceIngredient from "../admin/ReplaceIngredient";
import SupportUs from "../support";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/drinks/:id" element={<DrinkDetail />} />
      <Route path="/ingredients/:id" element={<IngredientDetail />} />
      <Route path="/ingredients" element={<IngredientsIndex />} />
      <Route path="/drinks" element={<DrinksIndex />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/search" element={<Search />} />
      <Route path="/admin/drinks/create" element={<CreateDrink />} />
      <Route path="/admin/drinks/edit/:id" element={<EditDrink />} />
      <Route path="/admin/ingredients/create" element={<CreateIngredient />} />
      <Route path="/admin/ingredients/edit/:id" element={<EditIngredient />} />
      <Route path="/admin/replace/ingredient" element={<ReplaceIngredient />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/support" element={<SupportUs />} />
      <Route path="/" element={<Home />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default AppRoutes;
