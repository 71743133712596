import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DrinkIngredientsControl from "./DrinkIngredientsControl";

const DrinkForm = ({ drink, onSubmit, disabled }) => {
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [referenceSources, setReferenceSources] = useState();
  const [tags, setTags] = useState();
  const [baseSpirit, setBaseSpirit] = useState("other");
  const [steps, setSteps] = useState();
  const [ingredients, setIngredients] = useState([]);

  useEffect(() => {
    if (!drink) return;

    setName(drink.name);
    setDescription(drink.description);
    setReferenceSources(drink.referenceSources.join());
    setTags(drink.tags.join());
    setBaseSpirit(drink.baseSpirit);
    setSteps(drink.steps.join("\n"));
    setIngredients(drink.ingredients);
  }, [drink]);

  const handleSubmitButtonClick = () => {
    const drinkObj = {
      ...drink,
      name: name,
      description: description,
      referenceSources: referenceSources.split(","),
      tags: tags.split(","),
      baseSpirit: baseSpirit,
      steps: steps.split("\n"),
      ingredients: ingredients.map((x) => {
        return {
          ...x,
          id: x.ingredientId,
          name: x.ingredientName,
        };
      }),
    };
    console.debug("handleSubmitButtonClick", drinkObj);
    onSubmit(drinkObj);
  };

  return (
    <div>
      <Form>
        <Form.Group className="mb-2">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            defaultValue={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Reference Sources</Form.Label>
          <Form.Control
            type="text"
            defaultValue={referenceSources}
            onChange={(e) => setReferenceSources(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Tags</Form.Label>
          <Form.Control
            type="text"
            defaultValue={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Base Spirit</Form.Label>
          <Form.Control
            as="select"
            defaultValue={baseSpirit}
            onChange={(e) => setBaseSpirit(e.target.value)}
          >
            <option>rum</option>
            <option>gin</option>
            <option>vodka</option>
            <option>brandy</option>
            <option>tequila</option>
            <option>mezcal</option>
            <option>combination</option>
            <option>other</option>
          </Form.Control>
        </Form.Group>
        <DrinkIngredientsControl
          ingredients={ingredients}
          onChange={(obj) => setIngredients(obj)}
        />
        <Form.Group className="mb-2">
          <Form.Label>Steps</Form.Label>
          <Form.Control
            as="textarea"
            defaultValue={steps}
            onChange={(e) => setSteps(e.target.value)}
          />
        </Form.Group>
      </Form>
      <Button className="mt-4" onClick={handleSubmitButtonClick} disabled={disabled}>
        Submit
      </Button>
    </div>
  );
};

export default DrinkForm;
