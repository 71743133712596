import {isMobile} from 'react-device-detect';

const OpenDonateLink = () => {
    const donateUrl = "https://buymeacoff.ee/mixirs";

    if(isMobile){
        window.location.assign(donateUrl);
    }else{
        window.open(donateUrl);
    }
}
 
export default OpenDonateLink;