import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import IngredientAddRemoveButton from "../ingredient-add-remove-button";
import EditIngredientButton from "../edit-ingredient-button";

const IngredientCard = ({ itm, hasIngredient }) => {
  return (
    <div className="mt-2">
      <Card className="ingredient-card">
        <Card.Body>
          <Card.Title>
            <Link to={`/ingredients/${itm.id}`} className="text-dark">
              {itm.name}
            </Link>
          </Card.Title>
          <Card.Subtitle className="text-muted">
            {itm.ingredientType}
          </Card.Subtitle>
        </Card.Body>

        <Card.Footer>
          <IngredientAddRemoveButton
            ingredientId={itm.id}
            isAdded={hasIngredient}
          />
          <EditIngredientButton ingredient={itm} />
        </Card.Footer>
      </Card>
    </div>
  );
};

export default IngredientCard;
