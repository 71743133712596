import cocktailImage1 from "../images/drinks/johann-trasch-2ssSOO2pPK8-unsplash.jpg";
import cocktailImage2 from "../images/drinks/johann-trasch-bcXKAI-0Zx0-unsplash.jpg";
import cocktailImage3 from "../images/drinks/johann-trasch-b-3uxA93zUc-unsplash.jpg";
import RandomDrinkPanel from "../random-drink-panel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const LandingPageDetails = () => {
  return (
    <div className="mt-4">
      <Row>
        <Col sm={{ span: 5, offset: 1 }} xl={{ span: 4, offset: 2 }}>
          <h1 className="display-4 text-center">
            Relevant, Distinctive Cocktail
          </h1>
          <p className="lead text-center">
            With Mixirs, build a unique, tailored library of enticing cocktails
            from only what you have at home.
          </p>
        </Col>
        <Col xl={{ span: 4 }} sm={{ span: 5, offset: 1 }}>
          <img
            src={cocktailImage1}
            className="img-fluid rounded"
            alt="cocktail"
          />
        </Col>
      </Row>
      <div className="mt-4">
        <RandomDrinkPanel />
      </div>
      <Row className="mt-4">
        <Col
          xl={{ span: 4, offset: 2 }}
          sm={{ span: 5, offset: 1 }}
          className="text-center"
        >
          <img
            src={cocktailImage2}
            className="img-fluid rounded"
            alt="cocktail"
          />
        </Col>
        <Col xl={4} sm={4}>
          <h1 className="display-4 text-center">
            Thousands of drinks from over 150 years of cocktail history
          </h1>
          <p className="lead">
            Let Mixirs guide you to a selection of drinks you can make right now
            with any home bar. Change up your go-to cocktail or discover
            something new.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xl={{ span: 4, offset: 2 }} sm={{ span: 5, offset: 1 }}>
          <h1 className="display-4">Enroll today</h1>
          <p className="lead">
            Dust off that home bar, peek into your pantry and dig into your
            crisper. You never know what ingredients you have until you look.
          </p>
          {/* Button Goes HERE */}
        </Col>
        <Col xl={{ span: 4 }} sm={{ span: 5 }} className="text-center">
          <img
            src={cocktailImage3}
            className="img-fluid rounded"
            alt="cocktail"
          />
        </Col>
      </Row>
      <div className="mt-4">
        <RandomDrinkPanel />
      </div>
    </div>
  );
};

export default LandingPageDetails;
