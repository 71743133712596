import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

const FacetAccordion = ({ title, values, onChange }) => {
  const upArrow = "⯅";
  const downArrow = "⯆";
  const [facets, setFacets] = useState([]);
  const [toggles, setToggles] = useState([]);
  const [titleLabel, setTitleLabel] = useState(`${title} ${upArrow}`);

  useEffect(() => {
    const validated = values.filter((x) => x);
    setFacets(validated);
    setToggles(new Array(validated.length).fill(true));
  }, [values]);

  const handleCheckChange = (position) => {
    const updated = toggles.map((toggle, index) =>
      index === position ? !toggle : toggle
    );

    setToggles(updated);

    const selected = facets.filter((_, index) => updated[index]);
    onChange(selected);
  };

  const handleOnToggle = () => {
    if(titleLabel.includes(upArrow)){
      setTitleLabel(`${title} ${downArrow}`);
    }else{
      setTitleLabel(`${title} ${upArrow}`);
    }
  };

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header>
          <CustomToggle eventKey="0" onToggle={handleOnToggle}>
            {titleLabel}
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <div>
            <ListGroup variant="flush">
              {facets.map((facet, index) => (
                <ListGroup.Item key={index}>
                  <Form.Check
                    type="checkbox"
                    label={facet}
                    value={facet}
                    onChange={() => handleCheckChange(index)}
                    checked={toggles[index]}
                  />
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

const CustomToggle = ({ children, eventKey, onToggle }) => {
  const customOnClick = useAccordionButton(eventKey, () => {
    onToggle();
  });

  return (
    <Button text="dark" variant="link" onClick={customOnClick}>
      {children}
    </Button>
  );
};

FacetAccordion.defaultProps = {
  title: "",
  values: [],
};

export default FacetAccordion;
