import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import DrinkSearchPanel from "./drink-search-panel";
import IngredientSearchPanel from "./ingredient-search-panel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Search = () => {
  const [searchParams] = useSearchParams();
  const [searchTerm] = useState(searchParams.get("q") ?? "");

  return (
    <Container fluid>
      <Row>
        <Col md lg={6}>
          <DrinkSearchPanel searchTerm={searchTerm} />
        </Col>
        <Col md lg={6}>
          <IngredientSearchPanel searchTerm={searchTerm} />
        </Col>
      </Row>
    </Container>
  );
};

export default Search;
