import { useState, useEffect, useContext, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DrinkSearchPanel from "./search-panel";
import DrinkGrid from "./drink-grid";
import { FetchLibrary, FetchFavorites } from "../clients/FetchUserData";
import UserContentContext from "../context/user-data-context";
import FetchDrinks from "../clients/FetchDrinks";
import PaginationNav from "../pagination-nav";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";
import BuildDrinkSearchParams from "./BuildDrinkSearchParams";
import SearchParamKeys from "../common/SearchParamKeys";
import { SearchTypes, ViewModes } from "../common/SearchConstants";

const DrinksIndex = () => {
  document.title = "mixirs | drinks";
  const pageSize = 24;
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(
    searchParams.get(SearchParamKeys.page_number) ?? 0
  );
  const [searchText, setSearchText] = useState(
    searchParams.get(SearchParamKeys.search_text) ?? ""
  );
  const [selectedMaxIngredients, setSelectedMaxIngredients] = useState(
    searchParams.get(SearchParamKeys.max_ingredient)
  );
  const [sortMode, setSortMode] = useState(
    searchParams.get(SearchParamKeys.order_by) ?? ""
  );
  const [viewMode, setViewMode] = useState(
    searchParams.get(SearchParamKeys.view_mode) ?? ""
  );
  const [selectedSources, setSelectedSources] = useState(
    searchParams.getAll(SearchParamKeys.source_facet) ?? []
  );
  const [selectedSpirits, setSelectedSpirits] = useState(
    searchParams.getAll(SearchParamKeys.base_spirit_facet) ?? []
  );

  const [totalCount, setTotalCount] = useState(0);
  const [sessionId, setSessionId] = useState(uuidv4());
  const { userContent } = useContext(UserContentContext);
  const [favorites, setFavorites] = useState();
  const [drinks, setDrinks] = useState([]);
  const [sources, setSources] = useState([]);
  const [spirits, setSpirits] = useState([]);
  const [maxIngredients, setMaxIngredients] = useState(0);
  const [searchRequired, setSearchRequired] = useState(SearchTypes.full);

  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!userContent) {
      console.log("did not find user content...");
      return;
    }

    const getFavorites = async () => {
      const obj = await FetchFavorites(
        userContent.accessToken,
        userContent.favorites
      );
      setFavorites(obj);
    };

    getFavorites();
  }, [userContent]);

  const buildDrinkFilter = useCallback(
    async (viewMode) => {
      let drinkFilter = [];
      if (viewMode === ViewModes.library) {
        const lib = await FetchLibrary(
          userContent.accessToken,
          userContent.library
        );
        drinkFilter = lib.drinks.map((d) => d.id);
      } else if (viewMode === ViewModes.bookmark) {
        const fav = await FetchFavorites(
          userContent.accessToken,
          userContent.favorites
        );
        drinkFilter = fav.bookmarks;
        setFavorites(fav);
      }

      return drinkFilter;
    },
    [userContent]
  );

  const completeFullSearch = useCallback((searchData) => {
    setDrinks(searchData.data);
    setTotalCount(searchData.totalCount);
    setSessionId(searchData.sessionId);
    setPageNumber(0);
    setSources(searchData.sourceFacets);
    setSelectedSources([]);
    setSpirits(searchData.tagFacets);
    setSelectedSpirits([]);
    setMaxIngredients(searchData.maxIngredients);
  }, []);

  const completeFilter = useCallback((searchData) => {
    setDrinks(searchData.data);
    setTotalCount(searchData.totalCount);
  }, []);

  const doSearch = useCallback(
    async (mode) => {
      if (viewMode !== "" && !userContent) {
        loginWithRedirect({ redirectUri: window.location.origin });
      }

      const drinkFilter = await buildDrinkFilter(viewMode);

      const body = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        sessionId: mode === SearchTypes.full ? uuidv4() : sessionId,
        searchText: searchText,
        sourceFilter: selectedSources,
        maxDrinkIngredients: selectedMaxIngredients ?? 0,
        baseSpirits: selectedSpirits,
        orderBy: sortMode,
        drinkFilter: drinkFilter,
        searchFields: [
          "name",
          "description",
          "tags",
          "ingredients/ingredientName",
        ],
      };

      setSearchParams(BuildDrinkSearchParams(body, viewMode));

      console.debug(body);

      const search = await FetchDrinks(body);
      console.debug("doSearch", search);

      if (mode === SearchTypes.full) {
        completeFullSearch(search);
      }
      if (mode === SearchTypes.filter) {
        completeFilter(search);
      }
    },
    [
      buildDrinkFilter,
      loginWithRedirect,
      setSearchParams,
      completeFullSearch,
      completeFilter,
      selectedMaxIngredients,
      pageNumber,
      sessionId,
      searchText,
      selectedSources,
      selectedSpirits,
      sortMode,
      userContent,
      viewMode,
    ]
  );

  useEffect(() => {
    if (!searchRequired) {
      return;
    }

    setSearchRequired(null);
    doSearch(searchRequired);
  }, [searchRequired, doSearch]);

  const handleFilterClear = () => {
    setSearchText("");
    setPageNumber(0);
    setSelectedSources([]);
    setSelectedSpirits([]);
    setViewMode("");
    setSearchRequired(SearchTypes.full);
  };

  const onPageRequest = (requestedPageNumber) => {
    setPageNumber(requestedPageNumber);
    setSearchRequired(SearchTypes.filter);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Row>
        <Col lg={2}>
          <DrinkSearchPanel
            searchText={searchText}
            sources={sources}
            spirits={spirits}
            maxIngredients={maxIngredients}
            onSearchTextChange={(value) => setSearchText(value)}
            onSortChange={(value) => setSortMode(value)}
            onIngredientCountChange={(value) =>
              setSelectedMaxIngredients(value)
            }
            onViewModeChange={(value) => setViewMode(value)}
            onSpiritsChange={(value) => setSelectedSpirits(value)}
            onSourceChange={(value) => setSelectedSources(value)}
            onFilter={() => setSearchRequired(SearchTypes.filter)}
            onFullSearch={() => setSearchRequired(SearchTypes.full)}
            onFilterClear={handleFilterClear}
          />
        </Col>
        <Col>
          <DrinkGrid drinks={drinks} drinkBookmarks={favorites?.bookmarks} />
        </Col>
      </Row>
      <Row className="pt-4 justify-content-md-center">
        <Col xs lg={2}>
          <PaginationNav
            currentPage={pageNumber}
            totalItemCount={totalCount}
            pageSize={pageSize}
            onPageRequest={onPageRequest}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DrinksIndex;
