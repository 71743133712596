import logo from "../images/cocktail-shaker-gray.png";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import LoginLogoutButton from "./login-logout-button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import OpenDonateLink from "../OpenDonateLink";


const NavigationBar = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState(false);

  const handleSearchBoxChange = (e) => {
    console.debug(e.target.value, `/search?q=${searchTerm}`);
    setSearchTerm(e.target.value);
  };

  const handleOnClick = (path) => {
    console.debug("header - handleOnClick");
    navigate(path);
    setExpanded(false);
  };

  const handleSearch = () => {
    navigate({
      pathname: "/search",
      search: `?q=${searchTerm}`,
    });
    setExpanded(false);
  };

  const handleFormSubmit = (event) => {
    console.debug("enter");
    event.preventDefault();
    navigate({
      pathname: "/search",
      search: `?q=${searchTerm}`,
    });
    setExpanded(false);
  };

  const handleSearchBoxClick = (event) => {
    console.debug("handleSearchBoxClick");
    setExpanded(true);
  };

  const handleOnToggle = (event) => {
    console.debug("handleOnToggle");
    setExpanded(!expanded);
  };

  return (
    <Navbar
      collapseOnSelect
      bg="white"
      expand="lg"
      onToggle={handleOnToggle}
      expanded={expanded}
    >
      <Navbar.Brand>
        <Nav.Link onClick={() => handleOnClick("/")}>
          <img src={logo} height="40" alt="logo" />
        </Nav.Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link onClick={() => handleOnClick("/drinks")}>Drinks</Nav.Link>
          <Nav.Link onClick={() => handleOnClick("/ingredients")}>
            Ingredients
          </Nav.Link>
          <Nav.Link onClick={() => handleOnClick("/contact")}>Contact</Nav.Link>
          <Nav.Link onClick={OpenDonateLink}>
            Donate!
          </Nav.Link>
          <Nav.Link onClick={() => handleOnClick("/")}>Home</Nav.Link>
        </Nav>
        <Form className="d-flex" onSubmit={handleFormSubmit}>
          <FormControl
            type="text"
            placeholder="search"
            className="mr-sm-2"
            onChange={handleSearchBoxChange}
            onClick={handleSearchBoxClick}
          />
          <Button onClick={handleSearch} variant="outline-success">
            Search
          </Button>
        </Form>
        <LoginLogoutButton />
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
