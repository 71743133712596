const PostIngredient = async (ingredient, accessToken) => {
  const url = `${process.env.REACT_APP_MIXIRS_API}/api/ingredients`;
  const body = {
    name: ingredient.name,
    description: ingredient.description,
    type: ingredient.ingredientType,
    baseType: ingredient.baseType,
    idx: true,
    tags: ingredient.tags,
  };

  const request = {
    method: "post",
    withCredentials: true,
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(body),
  };

  const response = await fetch(url, request);
  var responseBody = await response.json();

  console.log("PostIngredient", responseBody);

  return response;
};

export default PostIngredient;
