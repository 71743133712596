import Button from "react-bootstrap/Button";
import { useState, useEffect, useContext } from "react";
import UserContentContext from "../context/user-data-context";
import { useAuth0 } from "@auth0/auth0-react";
import BookmarkDrink from "../clients/BookmarkDrink";
import useUserRole from "../hooks/useUserRole";
import Roles from "../common/Roles";

const DrinkBookmarkButton = ({ drinkId, isBookmarked }) => {
  const { userContent } = useContext(UserContentContext);
  const [buttonStyle, setButtonStyle] = useState("secondary");
  const [buttonLabel, setButtonLabel] = useState("bookmark");
  const [bookmarked, setBookmarked] = useState(isBookmarked);
  const [disabled, setDisabled] = useState(true);
  const { loginWithRedirect } = useAuth0();
  const userRole = useUserRole();

  useEffect(() => {
    if (bookmarked) {
      setButtonStyle("info");
      setButtonLabel("bookmarked");
    } else {
      setButtonStyle("secondary");
      setButtonLabel("bookmark");
    }
  }, [bookmarked]);

  useEffect(() => {
    if (userRole !== Roles.unknown) {
      setDisabled(false);
    }
  }, [userRole]);

  const handleBookmarkClick = () => {
    console.log(`Bookmark Click - ${drinkId}`)
    setDisabled(true);
    
    const doBookmarkAction = async (newBookmarkValue) => {
      console.log('setBookmark');
      try {
        if (!userContent?.accessToken) {
          loginWithRedirect({ redirectUri: window.location.origin });
        }

        const actionValue = newBookmarkValue ? "add" : "delete";
        const response = await BookmarkDrink(drinkId, actionValue, userContent);

        if (response.ok) {
          setBookmarked(newBookmarkValue);
        } else {
          const msg = await response.text();
          console.error(`Error bookmarking drink - ${msg}`);
        }

        setDisabled(false);
      } catch (ex) {
        console.error(ex);
      }
    };

    doBookmarkAction(!bookmarked);
  };

  return (
    <Button
      variant={buttonStyle}
      onClick={handleBookmarkClick}
      size="sm"
      disabled={disabled}
    >
      {buttonLabel}
    </Button>
  );
};

export default DrinkBookmarkButton;
