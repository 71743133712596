import { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { useAuth0 } from "@auth0/auth0-react";

const LoginLogoutButton = () => {
  const [buttonTitle, setButtonTitle] = useState("Login");
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout } =
    useAuth0();

  useEffect(() => {
    (async () => {
      if (isLoading) return;

      if (!user || !isAuthenticated) {
        return;
      }

      setButtonTitle("Logout");
    })();
  }, [user, isAuthenticated, isLoading]);

  const handleClick = () => {
    if (buttonTitle === "Login") {
      setButtonTitle("Loading...");
      loginWithRedirect({
        redirectUri: window.location.origin,
        appState: { returnTo: window.location.pathname },
      });
    } else if (buttonTitle === "Logout") {
      setButtonTitle("Loading...");
      logout({ returnTo: window.location.origin });
    }
  };

  return (
    <div>
      <Nav>
        <Nav.Link onClick={handleClick}>{buttonTitle}</Nav.Link>
      </Nav>
    </div>
  );
};

export default LoginLogoutButton;
