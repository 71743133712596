import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const IngredientForm = ({ ingredient, onSubmit }) => {
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [type, setType] = useState('Spirit');
  const [baseType, setBaseType] = useState();
  const [tags, setTags] = useState();

  useEffect(() => {
    if (!ingredient) return;

    setName(ingredient.name);
    setDescription(ingredient.description);
    setType(ingredient.type);
    setBaseType(ingredient.baseType);
    setTags(ingredient.tags.join(","));
  }, [ingredient]);

  const handleSubmitButtonClick = () => {
    const obj = {
      ...ingredient,
      name: name,
      description: description,
      ingredientType: type,
      baseType: baseType,
      tags: tags?.split(","),
    };

    console.debug("handleSubmitButtonClick", obj);
    onSubmit(obj);
  };

  return (
    <>
      <Form>
        <Form.Group className="mb-2">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            defaultValue={description}
            rows={3}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Ingredient Type</Form.Label>
          <Form.Control
            as="select"
            defaultValue={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option>Spirit</option>
            <option>Adjunct</option>
            <option>Bitters</option>
            <option>Syrup</option>
            <option>Produce</option>
            <option>Pantry</option>
            <option>Garnish</option>
            <option>Liqueur</option>
            <option>Beer</option>
            <option>Wine</option>
            <option>Tea</option>
            <option>Unknown</option>
          </Form.Control>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Base Type</Form.Label>
          <Form.Control
            type="text"
            defaultValue={baseType}
            onChange={(e) => setBaseType(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Tags</Form.Label>
          <Form.Control
            type="text"
            defaultValue={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </Form.Group>
      </Form>
      <Button className="mt-4" onClick={handleSubmitButtonClick}>
        Submit
      </Button>
    </>
  );
};

export default IngredientForm;
