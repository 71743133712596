import React from "react";
import FormControl from "react-bootstrap/FormControl";
import SortBox from "./sort-box";
import IngredientFacetsPanel from "./ingredient-facets-panel";

const SearchPanel = ({
  searchTerm,
  ingredientTypes,
  sortMode,
  selectedIngredientTypes,
  onSearchTermChange,
  onSortChange,
  onIngredientTypeChange,
  onSearch,
  onFilter,
}) => {
  
  const handleKeyPress = (target) => {
    if (target.charCode === 13) {
      onSearch();
    }
  };

  const handleSearchTermChange = (e) => {
    onSearchTermChange(e.target.value);
  };

  const filterValueChanged = (stateFunc) => {
    stateFunc();
    onFilter(true);
  };

  const handleClear = () => {
    onSearchTermChange("");
    onSortChange("");
    onIngredientTypeChange([]);
    onSearch();
  };

  return (
    <div>
      <FormControl
        type="text"
        placeholder="search"
        onChange={handleSearchTermChange}
        value={searchTerm}
        onKeyPress={handleKeyPress}
      />
      <SortBox
        defaultSelection={sortMode}
        onSortChange={onSortChange}
      />

      <IngredientFacetsPanel
        facets={ingredientTypes}
        defaultSelection={selectedIngredientTypes}
        onChange={(value) =>
          filterValueChanged(() => onIngredientTypeChange(value))
        }
      />
      <button
        type="button"
        className="btn btn-outline-secondary btn-block mt-0"
        onClick={handleClear}
      >
        Clear
      </button>
    </div>
  );
};

export default SearchPanel;
