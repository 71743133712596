const PutIngredient = async (ingredient, accessToken) => {
  const url = `${process.env.REACT_APP_MIXIRS_API}/api/ingredients/${ingredient.id}`;
  const body = {
    name: ingredient.name,
    description: ingredient.description,
    type: ingredient.ingredientType,
    baseType: ingredient.baseType,
    idx: true,
    tags: ingredient.tags,
  };


  const request = {
    method: "put",
    withCredentials: true,
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(body),
  };

  const response = await fetch(url, request);

  console.log("PutIngredient", response);

  return response;
};

export default PutIngredient;
