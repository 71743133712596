import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import DrinkCard from "../drink-card";

const SimilarDrinks = ({ drinkIds }) => {
  const [drinks, setDrinks] = useState([]);
  const loadDrinks = Array.isArray(drinkIds) && drinkIds.length;

  useEffect(() => {
    if (loadDrinks) {
      var body = JSON.stringify({
        ids: drinkIds,
        random: false,
      });
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      };

      fetch(`${process.env.REACT_APP_MIXIRS_API}/api/drinks/query`, requestOptions)
        .then((response) => response.json())
        .then(
          (data) => {
            setDrinks(data.drinks);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }, [loadDrinks, drinkIds]);

  if (Array.isArray(drinkIds) && drinkIds.length) {
    return (
      <div className="mt-4">
        <Row>
          <Col>
            <h4>Similar Drinks</h4>
          </Col>
        </Row>
        <Row>
          {drinks.map((d) => (
            <Col xs={12} md={4} key={d.id}>
              <DrinkCard drink={d} />
            </Col>
          ))}
        </Row>
      </div>
    );
  }

  return null;
};

export default SimilarDrinks;
