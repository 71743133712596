import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

const SortBox = ({ onSortChange }) => {

  const handleChange = (event) => {
    // console.info("handleChange", event.target.value);
    onSortChange(event.target.value);
  };

  return (
    <div>
      <InputGroup>
        <InputGroup.Text>Sort</InputGroup.Text>
        <Form.Control
          as="select"
          aria-label="Select sort order"
          defaultValue=""
          onChange={handleChange}
        >
          <option value="">Best Match</option>
          <option value="name asc">Name (A-Z)</option>
          <option value="name desc">Name (Z-A)</option>
          <option value="count asc"># Ingredients (Lo-Hi)</option>
          <option value="count desc"># Ingredients (Hi-Lo)</option>
          <option value="created asc">Created (Old-New)</option>
          <option value="created desc">Created (New-Old)</option>
          <option value="updated asc">Updated (Old-New)</option>
          <option value="updated desc">Updated (New-Old)</option>
        </Form.Control>
      </InputGroup>
    </div>
  );
};

export default SortBox;
