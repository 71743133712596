import Button from "react-bootstrap/Button";

const RunIndexersButton = ({ indexName, accessToken }) => {
  const updateIndexers = async () => {
    const url = `${process.env.REACT_APP_MIXIRS_API}/api/search/${indexName}/update`;
    const request = {
      method: "put",
      withCredentials: true,
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }),
    };

    const response = await fetch(url, request);
    console.log("updateIndexers", response.ok);
    if (response.ok) {
      alert(`Indexer ${indexName}: success`);
    } else {
      alert(`Indexer ${indexName}: failed`);
    }
  };

  return (
    <Button variant="primary" onClick={async () => await updateIndexers()}>
      Run Indexer: {indexName}
    </Button>
  );
};

export default RunIndexersButton;
