import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

const MaxIngredientSelection = ({ maxIngredients, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <InputGroup>
      <InputGroup.Text>Max Ingredients</InputGroup.Text>
      <Form.Control as="select" onChange={handleChange}>
        <option>-</option>
        {[...Array(maxIngredients).keys()].map((i) => (
          <option key={i} value={i}>
            {i}
          </option>
        ))}
      </Form.Control>
    </InputGroup>
  );
};

export default MaxIngredientSelection;
