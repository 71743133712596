import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Roles from "../common/Roles";
import UserContentContext from "../context/user-data-context";
import useUserRole from "../hooks/useUserRole";
import IngredientForm from "./components/IngredientForm";
import ErrorPage from "../error-page";
import PostIngredient from "../clients/PostIngredient";

const CreateIngredient = () => {
  const userRole = useUserRole();
  const { userContent } = useContext(UserContentContext);
  const navigate = useNavigate();

  const handleSubmit = (ingredient) => {
    const doPost = async () => {
      const response = await PostIngredient(
        ingredient,
        userContent.accessToken
      );
      if (response.ok) {
        alert("drink added");
        navigate("/admin");
      } else {
        alert("error");
        console.error(response);
      }
    };

    doPost();
  };

  if ((userRole ?? Roles.unknown) !== Roles.admin) {
    return <ErrorPage />;
  }

  return (
    <div>
      <h2 className="mb-4">Create Ingredient</h2>
      <IngredientForm onSubmit={handleSubmit} />
    </div>
  );
};

export default CreateIngredient;
