import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";

const IngredientSearchEntry = ({ ingredient }) => {
  const ShortIngredientDescription = (itm) => {
    if (itm.description.length > 100) {
      const words = itm.description.split(" ").slice(0, 10).join(" ");
      return `${words}...`;
    }

    return itm.description.substring(0, 100);
  };

  return (
    <div className="mb-4">
      <strong>
        <Link to={`/ingredients/${ingredient.id}`}>{ingredient.name}</Link>
      </strong>
      <Badge className="ml-2" pill bg="primary" text="white">
        {ingredient.ingredientType}
      </Badge>
      <div className="text-muted">{ShortIngredientDescription(ingredient)}</div>
    </div>
  );
};

export default IngredientSearchEntry;
