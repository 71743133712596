import Button from "react-bootstrap/Button";
import { useState, useEffect, useContext } from "react";
import UserContentContext from "../context/user-data-context";
import { useAuth0 } from "@auth0/auth0-react";
import SetBarItem from "../clients/SetBarItem";
import useUserRole from "../hooks/useUserRole";
import Roles from "../common/Roles";

const IngredientAddRemoveButton = ({ ingredientId, isAdded }) => {
  const { userContent } = useContext(UserContentContext);
  const [buttonStyle, setButtonStyle] = useState("secondary");
  const [buttonLabel, setButtonLabel] = useState("bookmark");
  const [added, setAdded] = useState(isAdded);
  const { loginWithRedirect } = useAuth0();
  const userRole = useUserRole();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setAdded(isAdded);
  }, [isAdded]);

  useEffect(() => {
    if (added) {
      setButtonStyle("info");
      setButtonLabel("remove");
    } else {
      setButtonStyle("secondary");
      setButtonLabel("add");
    }
  }, [added]);

  useEffect(() => {
    if (userRole !== Roles.unknown) {
      setIsDisabled(false);
    }
  }, [userRole]);

  const handleBookmarkClick = (value) => {
    const setIngredient = async () => {
      setIsDisabled(true);
      try {
        if (!userContent?.accessToken) {
          loginWithRedirect({ redirectUri: window.location.origin });
        }

        const actionValue = value ? "add" : "delete";
        var response = await SetBarItem(ingredientId, actionValue, userContent);
        if (response.ok) {
          console.log(`ingredient ${ingredientId} addState ${value}`);
          setAdded(value);
        } else {
          var msg = await response.text();
          console.error("error setting ingredient", response, msg);
        }
      } catch (ex) {
        console.log(ex);
      }
      setIsDisabled(false);
    };

    setIngredient();
  };

  return (
    <Button
      variant={buttonStyle}
      onClick={() => handleBookmarkClick(!added)}
      size="sm"
      disabled={isDisabled}
    >
      {buttonLabel}
    </Button>
  );
};

export default IngredientAddRemoveButton;
