import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import DrinkCard from "../drink-card";
import { Link } from "react-router-dom";

const FeaturedDrinks = ({ ingredientId, ingredientName }) => {
  const [drinks, setDrinks] = useState([]);

  useEffect(() => {
    var body = {
      searchText: ingredientId,
      searchFields: ["ingredients/ingredientId"],
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };

    fetch(
      `${process.env.REACT_APP_MIXIRS_API}/api/search/drinks`,
      requestOptions
    )
      .then((response) => response.json())
      .then(
        (data) => {
          setDrinks(data.data.slice(0, 3));
        },
        (error) => {
          console.log(error);
        }
      );
  }, [ingredientId]);

  if (Array.isArray(drinks) && drinks.length) {
    return (
      <div className="muted-background mx-4">
        <Row>
          <Col>
            <p className="lead text-center">Featured Drinks</p>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          {drinks.map((d) => (
            <Col key={d.id} md={3}>
              <DrinkCard drink={d} />
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            <p className="lead text-center">
              <Link to={`/drinks/?q="${ingredientName.replace(" ", "+")}"`}>
                See All Drinks
              </Link>
            </p>
          </Col>
        </Row>
      </div>
    );
  }

  return null;
};

export default FeaturedDrinks;
