import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DrinkSearchEntry from "./search-entry/drink-search-entry";
import FetchDrinks from "../clients/FetchDrinks";
import ListGroup from "react-bootstrap/ListGroup";

const DrinkSearchPanel = ({ searchTerm }) => {
  const [drinks, setDrinks] = useState([]);

  useEffect(() => {
    const body = {
      searchText: searchTerm,
      searchFields: [
        "name",
        "description",
        "tags",
        "ingredients/ingredientName",
      ],
    };

    const doFetch = async () => {
      const tmp = await FetchDrinks(body);
      // console.debug(tmp);
      setDrinks(tmp.data);
    };

    doFetch();
  }, [searchTerm]);

  return (
    <div>
      <Row>
        <Col>
          <h1>Drinks</h1>
          <ListGroup>
            {drinks.map((drink) => (
              <DrinkSearchEntry key={drink.id} drink={drink} />
            ))}
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
};

export default DrinkSearchPanel;
