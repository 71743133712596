import IngredientSuggestionTextbox from "./ingredient-suggestion-textbox";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";

const IngredientSuggestionControl = ({ onAdd }) => {
  const [ingredient, setIngredient] = useState(null);
  const [inputValue, setInputValue] = useState();

  const addIngredient = () => {
    console.debug("add ingredient", ingredient);
    if (ingredient) {
      setInputValue("");
      onAdd(ingredient);
    }
  };

  const handleSelection = (value)=>{
    setIngredient(value);
    setInputValue(value.name);
  };

  return (
    <InputGroup className="mb-2">
      <InputGroup>Ingredients</InputGroup>
      <IngredientSuggestionTextbox
        onSelection={handleSelection}
        value={inputValue}
      />
      <Button variant="outline-secondary" onClick={addIngredient}>
        Add
      </Button>
    </InputGroup>
  );
};

export default IngredientSuggestionControl;
