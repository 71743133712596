import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import UserContentContext from "../context/user-data-context";
import DrinkForm from "./components/DrinkForm";
import FetchDrink from "../clients/FetchDrink";
import useUserRole from "../hooks/useUserRole";
import Roles from "../common/Roles";
import ErrorPage from "../error-page";
import PutDrink from "../clients/PutDrink";
import DeleteDrink from "../clients/DeleteDrink";
import DeleteButton from "./components/DeleteButton";

const EditDrink = () => {
  const userRole = useUserRole();
  const { id } = useParams();
  const [drink, setDrink] = useState();
  const navigate = useNavigate();
  const { userContent } = useContext(UserContentContext);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (userRole !== Roles.admin) {
      return;
    }
    const doFetch = async () => {
      const d = await FetchDrink(id);
      setDrink(d);
    };

    doFetch();
  }, [id, userRole]);

  if ((userRole ?? Roles.unknown) !== Roles.admin) {
    console.debug("User is - ", userRole);
    return <ErrorPage />;
  }

  const handleSubmit = (drink) => {
    setButtonDisabled(true);
    const doPut = async () => {
      const response = await PutDrink(drink, userContent?.accessToken);
      if (response.ok) {
        alert("Drink updated");
      } else {
        alert("error");
        console.error(response);
      }
      setButtonDisabled(false);
    };

    doPut();
  };

  const handleDelete = () => {
    setButtonDisabled(true);
    const doDelete = async () => {
      console.log("userContent", userContent);
      const response = await DeleteDrink(drink.id, userContent?.accessToken);
      if (response.ok) {
        alert("Drink deleted");
        navigate("/admin");
      } else {
        alert(`Error: ${response}`);
      }

      setButtonDisabled(false);
    };

    doDelete();
  };

  return (
    <div>
      <h2 className=" mb-4">Edit Drink</h2>
      <DrinkForm
        drink={drink}
        onSubmit={handleSubmit}
        disabled={buttonDisabled}
      />
      <div className="mt-4">
        <DeleteButton
          drink={drink}
          onDeleteConfirm={handleDelete}
          disabled={buttonDisabled}
        />
      </div>
    </div>
  );
};

export default EditDrink;
