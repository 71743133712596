import DrinkCard from "../drink-card";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const RandomDrinkPanel = () => {
  const [drinks, setDrinks] = useState([{}, {}, {}]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_MIXIRS_API}/api/Search/drinks/random?count=3&fast=true`
    )
      .then((res) => res.json())
      .then(
        (data) => {
          setDrinks(data);
          // setIsLoaded(true);
        },
        (error) => {
          // setIsLoaded(true);
          console.log(error);
        }
      );
  }, []);

  const handleAllDrinksClick = () => {
    console.log("click");
  };

  return (
    <div className="muted-background mx-4">
      <Row>
        <Col>
          <p className="lead text-center">Random Mixirs Pick</p>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        {drinks.map((d, i) => (
          <Col key={i} md={3}>
            <DrinkCard drink={d} />
          </Col>
        ))}
      </Row>
      <Row>
        <Col>
          <p onClick={handleAllDrinksClick} className="lead text-center">
            See All Drinks
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default RandomDrinkPanel;
