import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IngredientCard from "./ingredient-card";
import { useState, useEffect } from "react";

const IngredientCardGrid = ({ ingredients, bar }) => {
  const [isAvailable, setIsAvailable] = useState([]);

  useEffect(() => {
    const availabilityMap = ingredients.map((ingredient) =>
      bar?.availableIngredients.some((i) => i.id === ingredient.id)
    );
    setIsAvailable(availabilityMap);
  }, [ingredients, bar]);

  return (
    <Row xs={1} lg={3} className="mt g-4 ">
      {ingredients.map((ingredient, index) => (
        <Col key={ingredient.id}>
          <IngredientCard
            key={ingredient.id}
            itm={ingredient}
            hasIngredient={isAvailable[index]}
          />
        </Col>
      ))}
    </Row>
  );
};

export default IngredientCardGrid;
