import useUserRole from "../hooks/useUserRole";
import Roles from "../common/Roles";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const EditIngredientButton = ({ ingredient }) => {
  const userRole = useUserRole();
  const navigate = useNavigate();

  if (userRole !== Roles.admin) {
    return <div></div>;
  }

  return (
    <Button
      className="ml-2"
      size='sm'
      variant="outline-danger"
      onClick={() => navigate(`/admin/ingredients/edit/${ingredient.id}`)}
    >
      edit
    </Button>
  );
};

export default EditIngredientButton;
