import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IngredientSearchEntry from "./search-entry/ingredient-search-entry";
import FetchIngredients from "../clients/FetchIngredients";

const IngredientSearchPanel = ({ searchTerm }) => {
  const [ingredients, setIngredients] = useState([]);

  useEffect(() => {
    const body = {
      searchText: searchTerm,
      searchFields: [
        "name",
        "description",
        "tags",
        "ingredients/ingredientName",
      ],
    };

    const doFetch = async () => {
      const tmp = await FetchIngredients(body);
      console.debug(tmp);
      setIngredients(tmp.data);
    };

    doFetch();
  }, [searchTerm]);

  return (
    <div>
      <Row>
        <Col>
          <h1>Ingredients</h1>
          {ingredients.map((item) => (
            <IngredientSearchEntry key={item.id} ingredient={item} />
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default IngredientSearchPanel;
