import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useState, useEffect } from "react";

const IngredientFacetsPanel = ({ facets, selected, onChange }) => {
  const [types, setTypes] = useState([]);
  const [toggles, setToggles] = useState([]);

  useEffect(() => {
    setToggles(new Array(facets.length).fill(true));
    setTypes(facets);
  }, [facets]);

  const handleToggle = (position) => {
    const updatedToggle = toggles.map((toggle, index) =>
      index === position ? !toggle : toggle
    );

    setToggles(updatedToggle);

    const selectedFacets = facets.filter((i, idx) => updatedToggle[idx]);

    onChange(selectedFacets);
  };

  if (!facets) return null;

  return (
    <div>
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">Ingredient Types</CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <div>
              <ListGroup variant="flush">
                {types.map((i, idx) => (
                  <ListGroup.Item key={idx}>
                    <Form.Check
                      key={idx}
                      type="checkbox"
                      label={i}
                      checked={toggles[idx]}
                      value={i}
                      onChange={() => handleToggle(idx)}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

const CustomToggle = ({ children, eventKey }) => {
  const customOnClick = useAccordionButton(eventKey, () => {
    // do nothing
  });

  return (
    <Button text="dark" variant="link" onClick={customOnClick}>
      {children}
    </Button>
  );
};
export default IngredientFacetsPanel;
