import { Link } from "react-router-dom";
import DrinkShortDescription from "../../common/DrinkShortDescription";
import Badge from 'react-bootstrap/Badge';

const DrinkSearchEntry = ({ drink }) => {
  return (
    <div className="mb-4">
      <strong>
        <Link to={`/drinks/${drink.id}`}>{drink.name}</Link>
      </strong>
<Badge className='ml-2' pill bg='primary' text='white'>{drink.baseSpirit}</Badge>
      <div className="text-muted">{DrinkShortDescription(drink)}</div>
    </div>
  );
};

export default DrinkSearchEntry;
