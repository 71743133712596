const FetchIngredient = async (ingredientId) => {
    const request = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    };
    const searchUrl = `${process.env.REACT_APP_MIXIRS_API}/api/ingredients/${ingredientId}`;
    const response = await fetch(searchUrl, request);
    const data = await response.json();
  
    console.debug(data);
  
    return data;
  };
  
  export default FetchIngredient;
  