const DeleteDrink = async (drinkId, token) => {
  console.debug("delete drink", drinkId, token);
  const url = `${process.env.REACT_APP_MIXIRS_API}/api/drinks/${drinkId}?hardDelete=false`;
  const request = {
    method: "delete",
    withCredentials: true,
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  };

  const response = await fetch(url, request);
  //   const body = await response.json();
  console.log("DeleteDrink", response);

  return response;
};

export default DeleteDrink;
