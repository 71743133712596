import { useParams, Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import SimilarDrinks from "./similar-drinks";
import DrinkBookmarkButton from "../bookmark-button";
import FetchDrink from "../clients/FetchDrink";
import UserContentContext from "../context/user-data-context";
import { FetchFavorites } from "../clients/FetchUserData";
import EditDrinkButton from "../edit-drink-button";

const DrinkDetail = () => {
  const { id } = useParams();
  const [drink, setDrink] = useState(null);
  const { userContent } = useContext(UserContentContext);
  const [isBookmarked, setIsBookmarked] = useState(false);

  document.title = `mixirs | ${drink?.name}`;

  useEffect(() => {
    const getDrink = async () => {
      const data = await FetchDrink(id);
      console.debug(data);
      setDrink(data);
    };

    getDrink();
  }, [id]);

  useEffect(() => {
    if (!userContent) {
      return;
    }

    const getFavorites = async () => {
      const fav = await FetchFavorites(
        userContent.accessToken,
        userContent.favorites
      );
      if (fav.bookmarks.includes((x) => x === id)) {
        setIsBookmarked(true);
      } else {
        setIsBookmarked(false);
      }
    };

    getFavorites();
  }, [userContent, id]);

  if (!drink) {
    return null;
  }

  return (
    <div className="container">
      <Row>
        <Col>
          <span className="h1">{drink?.name}</span>
          <Badge pill bg="primary" text="white" className="ml-2">
            {drink?.baseSpirit}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col>
          <span className="h6 text-muted">
            Sources: {drink?.referenceSources.join(" | ")}
          </span>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <DrinkBookmarkButton drinkId={id} isBookmarked={isBookmarked} />
          <EditDrinkButton drink={drink} />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <p>{drink?.description}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>Ingredients</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            {drink?.ingredients.map((i) => (
              <li key={i.ingredientId}>
                <IngredientRow ingredient={i} />
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>Steps</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            {drink?.steps.map((s, idx) => (
              <li key={idx}>{s}</li>
            ))}
          </ul>
        </Col>
      </Row>

      <Row className="pt-4">
        {drink?.tags.map((tag, idx) => (
          <Badge pill bg="primary" text="white" key={idx} className="mr-2">
            {tag}
          </Badge>
        ))}
      </Row>

      <SimilarDrinks drinkIds={drink?.similarTo} />
      <Row></Row>
    </div>
  );
};

const DisplayQuantity = (quantity) => {
  const displayItems = new Map();
  displayItems.set("1/3", 0.33333333);
  displayItems.set("2/3", 0.66666666);
  let displayValue = quantity;

  displayItems.forEach((value, key) => {
    const r = quantity % 1;
    const delta = Math.abs(r - value);
    if (delta < 0.0001) {
      displayValue = key;
    }
  });

  return displayValue;
};

const IngredientRow = ({ ingredient }) => {
  if (ingredient.units.includes("Top Up")) {
    return <TopUpIngredientRow ingredient={ingredient} />;
  }
  return (
    <div>
      <div className="d-inline">{DisplayQuantity(ingredient.quantity)} </div>
      <div className="unitsLabel d-inline">{ingredient.units}</div>
      <div className="d-inline">
        {" "}
        of{" "}
        <Link to={`/ingredients/${ingredient.ingredientId}`}>
          {ingredient.ingredientName}
        </Link>
      </div>
      <IngredientModification ingredient={ingredient} />
      <IngredientOptional ingredient={ingredient} />
    </div>
  );
};

const TopUpIngredientRow = ({ ingredient }) => {
  return (
    <div>
      <div className="unitsLabel d-inline">{ingredient.units}</div>
      <div className="d-inline">
        {" "}
        of <Link to="">{ingredient.ingredientName}</Link>
      </div>
      <IngredientModification ingredient={ingredient} />
      <IngredientOptional ingredient={ingredient} />
    </div>
  );
};

const IngredientModification = ({ ingredient }) => {
  // console.debug(
  //   "modification",
  //   ingredient.ingredientId,
  //   ingredient.modification
  // );
  if (ingredient.modification) {
    return <div className="d-inline"> ({ingredient.modification}) </div>;
  }

  return null;
};

const IngredientOptional = ({ ingredient }) => {
  if (ingredient.optional) {
    return <div className="d-inline"> (OPTIONAL) </div>;
  }

  return null;
};

export default DrinkDetail;
