import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import SortBox from "./sort-box";
import MaxIngredientSelection from "./max-ingredient-selection";
import ViewModeSelection from "./view-mode-selection";
import FacetAccordion from "../../facet-accordion";

const DrinkSearchPanel = ({
  searchText,
  sources,
  spirits,
  maxIngredients,
  onFullSearch,
  onFilterClear,
  onSearchTextChange,
  onSortChange,
  onIngredientCountChange,
  onViewModeChange,
  onSpiritsChange,
  onSourceChange,
  onFilter,
}) => {
  const handleSearchTextChange = (event) => {
    onSearchTextChange(event.target.value);
  };

  const handleFullSearch = (event) => {
    event.preventDefault();
    onFullSearch();
  };

  const onFilteredValueChange = (propDataHandler) => {
    propDataHandler();
    onFilter();
  };

  useEffect(() => {
    console.debug("searchPanel", searchText);
  }, [searchText]);

  return (
    <div>
      <Form onSubmit={handleFullSearch}>
        <Form.Control
          type="text"
          placeholder="search"
          value={searchText}
          onChange={handleSearchTextChange}
        />
        <SortBox
          onSortChange={(value) =>
            onFilteredValueChange(() => onSortChange(value))
          }
        />
        <MaxIngredientSelection
          maxIngredients={maxIngredients}
          onChange={(value) =>
            onFilteredValueChange(() => onIngredientCountChange(value))
          }
        />
        <ViewModeSelection
          onSelectionChange={(value) =>
            onFilteredValueChange(() => onViewModeChange(value))
          }
        />
        <FacetAccordion
          title="Spirits"
          values={spirits}
          onChange={(value) =>
            onFilteredValueChange(() => onSpiritsChange(value))
          }
        />
        <FacetAccordion
          title="Sources"
          values={sources}
          onChange={(value) =>
            onFilteredValueChange(() => onSourceChange(value))
          }
        />
        <button
          type="button"
          className="btn btn-outline-secondary btn-block mt-0"
          onClick={onFilterClear}
        >
          Clear
        </button>
      </Form>
    </div>
  );
};

export default DrinkSearchPanel;
