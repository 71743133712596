const SearchTypes = {
  full: "full",
  filter: "filter",
};

const ViewModes = {
  all: "",
  library: "library",
  bookmark: "bookmark",
};

export { SearchTypes, ViewModes };
