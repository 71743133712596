import { useState, useContext } from "react";
import UserContentContext from "../context/user-data-context";
import DrinkForm from "./components/DrinkForm";
import useUserRole from "../hooks/useUserRole";
import Roles from "../common/Roles";
import ErrorPage from "../error-page";
import PostDrink from "../clients/PostDrink";
import { useNavigate } from "react-router-dom";

const CreateDrink = () => {
  const userRole = useUserRole();
  const { userContent } = useContext(UserContentContext);
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(false);

  if ((userRole ?? Roles.unknown) !== Roles.admin) {
    return <ErrorPage />;
  }

  const handleSubmit = (drink) => {
    setDisableButton(true);
    console.debug("handle Submit", userContent);
    const doPost = async () => {
      const response = await PostDrink(drink, userContent.accessToken);

      if (response.ok) {
        alert("drink added");
        console.log("drink added", response);
        navigate("/admin");
      } else {
        alert("error");
        console.error(response);
      }
      setDisableButton(false);
    };

    doPost();
  };

  return (
    <div>
      <h2 className="mb-4">Create Drink</h2>
      <DrinkForm onSubmit={handleSubmit} disabled={disableButton} />
    </div>
  );
};

export default CreateDrink;
