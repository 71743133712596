const PostDrink = async (drink, userToken) => {
  const url = `${process.env.REACT_APP_MIXIRS_API}/api/drinks`;
  const body = {
    name: drink.name,
    description: drink.description ?? "",
    referenceSources: drink.referenceSources,
    baseSpirit: drink.baseSpirit,
    tags: drink.tags,
    idx: true,
    ingredients: drink.ingredients.map((x) => {
      return {
        id: x.id,
        mod: x.modification ?? "",
        optional: x.optional ?? false,
        quantity: x.quantity,
        units: x.units,
      };
    }),
    steps: drink.steps,
  };

  console.debug("PostDrink", body, userToken);

  const request = {
    method: "post",
    withCredentials: true,
    headers: new Headers({
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(body),
  };

  const response = await fetch(url, request);
  var responseBody = await response.json();

  console.log("PostDrink", responseBody);

  return response;
};

export default PostDrink;
