import { useState, useEffect, useContext } from "react";
import IngredientSuggestionTextbox from "./components/IngredientSuggestionTextbox";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import DrinkSelectionTable from "./components/DrinkSelectionTable";
import FetchDrinks from "../clients/FetchDrinks";
import Button from "react-bootstrap/Button";
import UserContentContext from "../context/user-data-context";

const ReplaceIngredient = () => {
  const [badIngredient, setBadIngredient] = useState();
  const [goodIngredient, setGoodIngredient] = useState();
  const [drinks, setDrinks] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isOptional, setIsOptional] = useState(null);
  const [modification, setModification] = useState(null);
  const [status, setStatus] = useState([]);
  const { userContent } = useContext(UserContentContext);

  useEffect(() => {
    if (!badIngredient) {
      return;
    }
    console.debug("search for drinks", badIngredient);

    const doSearch = async () => {
      const request = {
        searchText: badIngredient.id,
        searchFields: ["ingredients/ingredientId"],
      };

      const response = await FetchDrinks(request);
      setDrinks(response.data);
    };

    doSearch();
  }, [badIngredient]);

  const submitChanges = () => {
    setStatus([]);
    selected.forEach((drink) => submitDrinkChange(drink));
  };

  const submitDrinkChange = async (drink) => {
    const body = {
      old: badIngredient.id,
      new: goodIngredient.id,
      mod: modification,
      optional: isOptional,
      drink: drink,
    };
    console.log("submit drink change", drink, body);
    const request = {
      method: "put",
      withCredentials: true,
      headers: new Headers({
        Authorization: `Bearer ${userContent.accessToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_MIXIRS_API}/api/replace/ingredient`,
        request
      );

      if(!response.ok){
        const errorMessage = await response.text();
        console.error(response, errorMessage);
      }

      setStatus([
        ...status,
        {
          id: drink.id,
          success: response.ok,
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <h2>Replacement</h2>
      <Row>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Find Ingredient</Form.Label>
            <IngredientSuggestionTextbox onSelection={setBadIngredient} />
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Label>Replace Ingredient</Form.Label>
            <IngredientSuggestionTextbox onSelection={setGoodIngredient} />
          </Form.Group>
          <Form.Check
            className="mt-4"
            type="switch"
            label="Optional"
            onBlur={(e) => setIsOptional(e.target.checked)}
          />
          <Form.Group className="mt-4">
            <Form.Label>Modification</Form.Label>
            <Form.Control
              type="text"
              onBlur={(e) => setModification(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <DrinkSelectionTable
            drinks={drinks}
            onSelection={setSelected}
            status={status}
          />
        </Col>
      </Row>
      <Button onClick={submitChanges}>Submit</Button>
    </>
  );
};

export default ReplaceIngredient;
