const FetchIngredients = async (requestBody) => {
  const request = {
    method: "post",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(requestBody),
  };
  const searchUrl = `${process.env.REACT_APP_MIXIRS_API}/api/search/ingredients`;
  const response = await fetch(searchUrl, request);
  const data = response.json();

  console.debug(data);

  return data;
};

export default FetchIngredients;
