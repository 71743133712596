import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import Roles from "../common/Roles";
import useUserRole from "../hooks/useUserRole";
import ErrorPage from "../error-page";
import RunIndexersButton from "./components/RunIndexersButton";
import { useContext } from "react";
import UserContentContext from "../context/user-data-context";

const Admin = () => {
  const userRole = useUserRole();
  const navigate = useNavigate();
  const { userContent } = useContext(UserContentContext);

  console.debug("admin page userRole - ", userRole);
  if ((userRole ?? Roles.unknown) === Roles.unknown) {
    return <ErrorPage />;
  }

  return (
    <Container fluid>
      <h2 className="display-4">Admin Console</h2>
      <Row>
        <Col className="mt-4" xs={12} lg={4}>
          <h4>Drinks</h4>
          {/* <Row>
            <Button onClick={() => navigate("/admin/drinks")}>
              View All Drinks
            </Button>
          </Row> */}
          <Row>
            <Button onClick={() => navigate("/admin/drinks/create")}>
              Create New Drinks
            </Button>
          </Row>
        </Col>
        <Col className="mt-4" xs={12} lg={4}>
          <h4>Ingredients</h4>
          {/* <Row>
            <Button>View All Ingredients</Button>
          </Row> */}
          <Row>
            <Button onClick={() => navigate("/admin/ingredients/create")}>
              Create New Ingredients
            </Button>
          </Row>
        </Col>
        <Col className="mt-4" xs={12} lg={4}>
          <h4>Utility</h4>
          <Row>
            <Button onClick={() => navigate("/admin/replace/ingredient")}>
              Replace Ingredient
            </Button>
          </Row>
          <Row className="mt-2">
            <RunIndexersButton
              indexName="drinks"
              accessToken={userContent?.accessToken}
            />
          </Row>
          <Row className="mt-2">
            <RunIndexersButton
              indexName="ingredients"
              accessToken={userContent?.accessToken}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Admin;
