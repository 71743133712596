import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import DrinkIngredientRow from './DrinkIngredientRow';
import IngredientSuggestionControl from "../../ingredient-suggestion-control";

const DrinkIngredientsControl = ({ ingredients, onChange }) => {
  const [drinkIngredients, setDrinkIngredients] = useState([]);

  useEffect(() => {
    setDrinkIngredients(ingredients);
  }, [ingredients]);

  const addIngredient = (ingredient) => {
    console.debug("addIngredient", ingredient);
    if (!ingredient) {
      return;
    }
    const newDrinkIngredient = {
      ingredientId: ingredient.id,
      ingredientName: ingredient.name,
      quantity: 0,
      units: "oz",
    };
    const tmp = [...ingredients, newDrinkIngredient];

    console.debug('add ingredients - updated', tmp);
    setDrinkIngredients(tmp);
    onChange(tmp);
  };

  const updateDrinkIngredient = (index, updated) => {
    console.debug('update drink ingredient', updated);
    let d = [...drinkIngredients];
    d[index] = {
      ...d[index],
      modification: updated.modification,
      optional: updated.optional,
      quantity: updated.quantity,
      units: updated.units,
    };

    console.debug("updatedDrinkIngredient", updated);

    setDrinkIngredients(d);
    onChange(d);
  };

  const deleteDrinkIngredient = (index) => {
    const tmp = [...drinkIngredients];
    tmp.splice(index,1);
    onChange(tmp);
    setDrinkIngredients(tmp);
  };

  return (
    <>
      <IngredientSuggestionControl onAdd={addIngredient} />
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Modification</th>
            <th>Optional</th>
            <th>Quantity</th>
            <th>Units</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {drinkIngredients.map((ingredient, index) => (
            <DrinkIngredientRow
              key={index}
              ingredient={ingredient}
              onChange={(x) => updateDrinkIngredient(index, x)}
              onDelete={() => deleteDrinkIngredient(index)}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default DrinkIngredientsControl;
