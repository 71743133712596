const FetchDrink = async (drinkId) => {
  const request = {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  };
  const searchUrl = `${process.env.REACT_APP_MIXIRS_API}/api/drinks/${drinkId}`;
  const response = await fetch(searchUrl, request);
  const data = response.json();

  console.debug(data);

  return data;
};

export default FetchDrink;
