import { useState, useEffect, useContext } from "react";
import UserContentContext from "../context/user-data-context";
import Roles from "../common/Roles";
import jwt_decode from "jwt-decode";

const useUserRole = () => {
  const { userContent } = useContext(UserContentContext);
  const [userRole, setUserRole] = useState(Roles.unknown);

  useEffect(() => {
    if (!userContent?.accessToken) {
      return;
    }

    const jwt = jwt_decode(userContent?.accessToken);
    const roles = [
      "drinks:create",
      "drinks:delete",
      "drinks:update",
      "ingredients:create",
      "ingredients:delete",
      "ingredients:update",
    ];

    const hasAll = roles.every((x) => jwt?.permissions.includes(x));
    if (hasAll) {
      setUserRole(Roles.admin);
    } else {
      setUserRole(Roles.user);
    }
  }, [userContent]);

  return userRole;
};

export default useUserRole;
