import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

const SortBox = ({ value, onSortChange }) => {

  const handleChange = (event) => {
    onSortChange(event.target.value);
  };

  return (
    <div>
      <InputGroup>
        <InputGroup.Text>Sort</InputGroup.Text>
        <Form.Control
          as="select"
          aria-label="Select sort order"
          defaultValue={value}
          onChange={handleChange}
        >
          <option value="">Best Match</option>
          <option value="name asc">Name (A-Z)</option>
          <option value="name desc">Name (Z-A)</option>
          <option value="count desc"># Drinks (Hi-Lo)</option>
          <option value="count asc"># Drinks (Lo-Hi)</option>
        </Form.Control>
      </InputGroup>
    </div>
  );
};

export default SortBox;
