import { Link } from "react-router-dom";
import OpenDonateLink from "../OpenDonateLink";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <>
      <div className="my-4 text-center">
        &copy; {year} - mixirs -
        <Link
          onClick={OpenDonateLink}
          to='#'
        >
          Support Mixirs
        </Link>{" "}
        - <Link to="/privacy">Privacy</Link>
      </div>
    </>
  );
};

export default Footer;
