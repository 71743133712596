import myImage from "../images/bar_book_cropped.jpg";
import LandingPageDetails from "./details";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useDynamicTextDisplay from "../hooks/useDynamicTextSize";

const Home = () => {
  const displayFactor = useDynamicTextDisplay('1', '4');

  return (
    <>
      <Row>
        <Col sm={{ span: 10, offset: 1 }}>
          <h1 className={`display-${displayFactor} text-center`}>
            Curated cocktails just for you
          </h1>
          <p className="lead text-center">
            Use what you already have to craft cocktails in the comfort of you
            own home
          </p>
        </Col>
      </Row>

      <Row>
        <Col sm={{span:10, offset:1}} xl={{span:8, offset: 2}} className="text-center">
          <img src={myImage} className="rounded img-fluid" alt="cocktail" />
        </Col>
      </Row>
      <LandingPageDetails />
    </>
  );
};

export default Home;
