import useWindowDimensions from "./useWindowDimensions";
import { useEffect, useState } from "react";

const useDynamicTextDisplay = ( defaultSize, smallSize, threshold ) => {
  const { width } = useWindowDimensions();
  const defaultLargeSize = "1";
  const defaultSmallSize = "4";
  const defaultThreshold = 992;
  const [displaySize, setDisplaySize] = useState("");

  useEffect(() => {
    const cutOver = threshold ?? defaultThreshold;
    console.log(defaultSize, smallSize, cutOver);
    if (width >= cutOver) {
      setDisplaySize(defaultSize ?? defaultLargeSize);
    } else {
      setDisplaySize(smallSize ?? defaultSmallSize);
    }
  }, [defaultSize, smallSize, threshold, width]);

  return displaySize;
};

export default useDynamicTextDisplay;
