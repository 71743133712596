const DeleteIngredient = async (ingredientId, accessToken) => {
    
    const url = `${process.env.REACT_APP_MIXIRS_API}/api/ingredients/${ingredientId}?hardDelete=false`;
    const request = {
      method: "delete",
      withCredentials: true,
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }),
    };
  
    const response = await fetch(url, request);
    //   const body = await response.json();
    console.log("DeleteIngredient", response);
  
    return response;
  };
  
  export default DeleteIngredient;
  