import { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";

const PaginationNav = ({
  currentPage,
  totalItemCount,
  pageSize,
  onPageRequest,
}) => {
  const [pageCount, setPageCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState([]);

  useEffect(() => {
    const cnt = Math.ceil(totalItemCount / pageSize);
    setPageCount(cnt);
  }, [totalItemCount, pageSize]);

  useEffect(() => {
    const data = [...Array(pageCount)].map((x, i) => currentPage === i);
    setIsDisabled(data);
  }, [pageCount, currentPage]);

  const onPageClick = (requested) => {
    if (requested === currentPage) {
      return;
    }
    if (requested < 0 || requested > pageCount - 1) {
      return;
    }

    console.debug("new page requested", requested);
    onPageRequest(requested);
  };

  if (pageCount <= 1) return null;

  return (
    <Pagination>
      <Pagination.First
        disabled={isDisabled[0]}
        onClick={() => onPageClick(0)}
      />
      <Pagination.Prev
        // disabled={isDisabled[currentPage]}
        onClick={() => onPageClick(currentPage - 1)}
      />

      <Pagination.Next onClick={() => onPageClick(currentPage + 1)} />
      <Pagination.Last
        disabled={isDisabled[pageCount - 1]}
        onClick={() => onPageClick(pageCount - 1)}
      />
    </Pagination>
  );
};

export default PaginationNav;
