const SearchParamKeys = {
    page_number: "pg",
    search_text: "q",
    max_ingredient: "n",
    order_by: "order",
    view_mode: "mode",
    source_facet: "s",
    base_spirit_facet: "b",
  };

  export default SearchParamKeys;