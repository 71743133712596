import FormControl from "react-bootstrap/FormControl";
import React, { useState, useEffect } from "react";
import { Dropdown, InputGroup } from "react-bootstrap";
import SuggestIngredients from "../clients/SuggestIngredients";

const IngredientSuggestionTextbox = ({ value, onSelection }) => {
  const [show, setShow] = useState(false);
  const [ingredients, setIngredients] = useState([]);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleOnChange = async (e) => {
    const input = e.target.value;
    setInputValue(e.target.value);
    if (input.length >= 3) {
      console.debug("do search - ", input);

      const suggested = await SuggestIngredients(input);
      console.debug("suggested", suggested);
      setIngredients(suggested.slice(0, 25));
      if (suggested.length > 0) {
        setShow(true);
      }

      return;
    }

    setShow(false);
  };

  const handleMenuSelection = (e) => {
    const ingredient = ingredients.find((x) => x.id === e);
    if (!ingredient) {
      return;
    }
    console.debug("selected", ingredient);
    setInputValue(ingredient.name);
    onSelection(ingredient);
    setShow(false);
  };

  return (
    <>
      <InputGroup>
        <FormControl
          type="text"
          onChange={handleOnChange}
          placeholder="add ingredient"
          value={inputValue}
        />
        <Dropdown
          show={show}
          onSelect={handleMenuSelection}
          autoClose="outside"
        >
          <Dropdown.Menu>
            {ingredients.map((ingredient) => (
              <Dropdown.Item key={ingredient.id} eventKey={ingredient.id}>
                {ingredient.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </InputGroup>
    </>
  );
};

export default IngredientSuggestionTextbox;
