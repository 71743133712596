const PutDrink = async (drink, token) => {
  const url = `${process.env.REACT_APP_MIXIRS_API}/api/drinks/${drink.id}`;
  const body = {
    name: drink.name,
    description: drink.description,
    referenceSources: drink.referenceSources,
    baseSpirit: drink.baseSpirit,
    tags: drink.tags,
    steps: drink.steps,
    similarTo: drink.similarTo,
    ingredients: drink.ingredients.map((x) => {
      return {
        id: x.id,  
        mod: x.modification ?? "",
        optional: x.optional ?? false,
        quantity: x.quantity,
        units: x.units,
      };
    }),
  };

  console.debug('Put Drink', body);
  const request = {
    method: "put",
    withCredentials: true,
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(body),
  };

  const response = await fetch(url, request);
  console.debug("Put Drink", response);

  return response;
};

export default PutDrink;
