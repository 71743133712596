import { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import UserContentContext from "../context/user-data-context";
import configData from "../config.json";

const AuthWrapper = () => {
  const { userContent, setUserContent } = useContext(UserContentContext);
  const {
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    (async () => {
      if (isLoading) return;

      if (!user || !isAuthenticated) {
        return;
      }

      if (userContent) {
        return;
      }

      const token = await getAccessTokenSilently({
        audience: configData.AUTH0_AUDIENCE,
        scope: "read:current_user",
      });

      console.debug("token -- ", token);

      const content = await fetch(`${process.env.REACT_APP_MIXIRS_API}/api/userContent`, {
        method: "get",
        withCredentials: true,
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
      }).then((r) => r.json());

      console.log("content ", content);

      setUserContent({ ...content, accessToken: token });
    })();
  }, [
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    setUserContent,
    userContent
  ]);

  return <div></div>;
};

export default AuthWrapper;
