import React, { useState, useEffect, useContext, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SearchPanel from "./search-panel";
import IngredientCardGrid from "./ingredient-card-grid";
import { FetchBar } from "../clients/FetchUserData";
import FetchIngredients from "../clients/FetchIngredients";
import UserContentContext from "../context/user-data-context";
import PaginationNav from "../pagination-nav";
import { useSearchParams } from "react-router-dom";

const IngredientsIndex = () => {
  document.title = "mixirs | ingredients";

  let [searchParams, setSearchParams] = useSearchParams();
  const pageSize = 24;
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(searchParams.get("pg") ?? 0);
  const [sessionId, setSessionId] = useState();
  const { userContent } = useContext(UserContentContext);
  const [bar, setBar] = useState();
  const [searchTerm, setSearchTerm] = useState(searchParams.get("q") ?? "");
  const [sortMode, setSortMode] = useState(searchParams.get("order") ?? "");
  const [ingredientTypes, setIngredientTypes] = useState([]);
  const [selectedIngredientTypes, setSelectedIngredientTypes] = useState(
    searchParams.getAll("i")
  );
  const [ingredients, setIngredients] = useState([]);
  const [searchRequired, setSearchRequired] = useState("full");

  useEffect(() => {
    const doSetBar = async () => {
      if (!userContent) {
        return;
      }

      const bar = await FetchBar(userContent.accessToken, userContent.bar);
      setBar(bar);
    };

    doSetBar();
  }, [userContent]);

  const doFullSearch = useCallback(async () => {
    const body = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      sessionId: uuidv4(),
      searchText: searchTerm,
      ingredientTypeFilter: selectedIngredientTypes,
      orderBy: { value: sortMode },
    };

    let newParams = new URLSearchParams();
    if (searchTerm?.length > 0) {
      newParams.append("q", searchTerm);
    }
    if (pageNumber > 0) {
      newParams.append("pg", pageNumber);
    }
    if (sortMode) {
      newParams.append("order", sortMode);
    }

    selectedIngredientTypes.forEach((s) => newParams.append("i", s));

    console.log(newParams);
    setSearchParams(newParams);

    try {
      const data = await FetchIngredients(body);
      console.debug("doFullSearch", data);

      setPageNumber(data.pageNumber);
      setIngredientTypes(data.ingredientFacets);
      setIngredients(data.data);
      setSessionId(data.sessionId);
      setTotalItemCount(data.totalCount);
    } catch (error) {
      console.error(error);
    }
  }, [
    pageNumber,
    searchTerm,
    sortMode,
    selectedIngredientTypes,
    setSearchParams,
  ]);

  const doFilterSearch = useCallback(async () => {
    const body = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      sessionId: sessionId,
      searchText: searchTerm,
      ingredientTypeFilter: selectedIngredientTypes,
      orderBy: { value: sortMode },
    };

    var newParams = new URLSearchParams();
    if (searchTerm?.length > 0) {
      newParams.append("q", searchTerm);
    }
    if (pageNumber > 0) {
      newParams.append("pg", pageNumber);
    }
    if (sortMode) {
      newParams.append("order", sortMode);
    }

    selectedIngredientTypes.forEach((s) => newParams.append("i", s));

    console.log(newParams);
    setSearchParams(newParams);

    try {
      console.debug("Soft Search - Request", body);
      const data = await FetchIngredients(body);
      console.debug("Soft Search - Response", data);

      setIngredients(data.data);
      setTotalItemCount(data.totalCount);
    } catch (error) {
      console.error(error);
    }
  }, [
    pageNumber,
    pageSize,
    sessionId,
    searchTerm,
    selectedIngredientTypes,
    sortMode,
    setSearchParams,
  ]);

  useEffect(() => {
    if (!searchRequired) return;

    setSearchRequired(null);
    if (searchRequired === "full") {
      doFullSearch();
    } else if (searchRequired === "filter") {
      doFilterSearch();
    }
  }, [searchRequired, doFullSearch, doFilterSearch]);

  const handlePageChange = (requestedPage) => {
    console.debug(requestedPage);
    setPageNumber(requestedPage);
    setSearchRequired("filter");
    window.scrollTo(0, 0);
  };

  const handleSortChange = (value) => {
    setPageNumber(0);
    setSortMode(value);
    setSearchRequired("filter");
  };

  return (
    <div>
      <Row>
        <Col lg={2} >
          <SearchPanel
            searchTerm={searchTerm}
            ingredientTypes={ingredientTypes}
            sortMode={sortMode}
            selectedIngredientTypes={selectedIngredientTypes}
            onSearchTermChange={(value) => setSearchTerm(value)}
            onSortChange={handleSortChange}
            onIngredientTypeChange={(value) =>
              setSelectedIngredientTypes(value)
            }
            onSearch={() => setSearchRequired("full")}
            onFilter={() => setSearchRequired("filter")}
          />
        </Col>
        <Col>
          <IngredientCardGrid ingredients={ingredients} bar={bar} />
        </Col>
      </Row>
      <Row className="pt-4 justify-content-md-center">
        <Col xs lg={2}>
          <PaginationNav
            currentPage={pageNumber}
            totalItemCount={totalItemCount}
            pageSize={pageSize}
            onPageRequest={handlePageChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default IngredientsIndex;
