import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import DrinkBookmarkButton from "../bookmark-button";
import DrinkShortDescription from "../common/DrinkShortDescription";
import EditDrinkButton from "../edit-drink-button";

const DrinkCard = ({ drink, showBookmark, isBookmarked }) => {

  const CardFooter = ({ showBookmark }) => {
    if (!showBookmark) {
      return null;
    }

    return (
      <Card.Footer>
        <DrinkBookmarkButton drinkId={drink.id} isBookmarked={isBookmarked} />
        <EditDrinkButton drink={drink} />
      </Card.Footer>
    );
  };

  if ("id" in drink) {
    return (
      <Card className="drink-card h-100">
        <Card.Body>
          <Card.Title>
            <Link to={`/drinks/${drink.id}`}>{drink.name}</Link>
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {drink.baseSpirit}
          </Card.Subtitle>
          <Card.Text>{DrinkShortDescription(drink)}</Card.Text>
        </Card.Body>
        <CardFooter showBookmark={showBookmark} />
      </Card>
    );
  } else {
    return (
      <Card className="drink-card h-100">
        <Card.Body>
          <Card.Title></Card.Title>
          <Card.Subtitle></Card.Subtitle>
          <Card.Text>Loading...</Card.Text>
        </Card.Body>
      </Card>
    );
  }
};

export default DrinkCard;
