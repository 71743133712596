const DrinkShortDescription = (drink) => {
  const desc = drink?.ingredients
    .map((i) => i.ingredientName)
    .slice(0, 3)
    .join(", ");

  return `${desc} ...`;
};

export default DrinkShortDescription;
