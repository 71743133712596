// import React from "react";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import configData from "../config.json";

const Profile = () => {
  document.title = `mixirs | profile`;
  const { user, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const doGetToken = async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: configData.AUTH0_AUDIENCE
        });

        setAccessToken(token);
      } catch (e) {
        console.error(e);
      }
    };

    doGetToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (user) {
      setUserData(user);
    }
  }, [user]);

  return (
    <div>
      <img src={userData?.picture} alt={userData?.name} />
      <h2>Name: {userData?.name}</h2>
      <p>Email: {userData?.email}</p>
      {/* <p>{JSON.stringify(user, null, '\t')}</p> */}
      <p></p>
      <p>JWT: {accessToken}</p>
    </div>
  );
};

export default Profile;
