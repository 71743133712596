import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

const ViewModeSelection = ({ onSelectionChange }) => {
  const handleOnChange = (event) => {
    onSelectionChange(event.target.value);
  };

  return (
    <InputGroup>
      <InputGroup.Text>View Mode</InputGroup.Text>
      <Form.Control as="select" onChange={handleOnChange}>
        <option value="all">All</option>
        <option value="library">Library</option>
        <option value="bookmark">Bookmark</option>
      </Form.Control>
    </InputGroup>
  );
};

export default ViewModeSelection;
