import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RandomDrinkPanel from "../random-drink-panel";
import cocktailImage3 from "../images/empty-bar.jpg";

const ErrorPage = () => {
  return (
    <div>
      <Row>
        <Col>
          <p className="display-4 text-center">
            Oops...that resource does not exist
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-center display-1">404</div>
        </Col>
      </Row>

      <Row>
        <Col className="text-center">
          <img
            src={cocktailImage3}
            className="img-fluid rounded"
            alt="empty bar"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <p className="lead text-center">
            Try one of theses instead
          </p>
        </Col>
      </Row>
      <div className="mt-4">
        <RandomDrinkPanel />
      </div>
    </div>
  );
};

export default ErrorPage;
