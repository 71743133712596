import { Row, Col } from "react-bootstrap";
import cocktailImage from "../images/drinks/johann-trasch-qAegSdhKwnE-unsplash.jpg";

const SupportUs = () => {
  const handleClick = () => {
    window.open("https://buymeacoff.ee/mixirs");
  };

  return (
    <>
      <Row>
        <Col sm={{ span: 10, offset: 1 }}>
          <h1 className="display-4 text-center">Support Mixirs</h1>
          <p className="lead text-center">
            If you're enjoying Mixirs consider buying us a drink
          </p>
        </Col>
      </Row>
      <Row>
        <Col xl={{ span: 4, offset: 4 }} sm={{ span: 6, offset: 3 }}>
          <img
            src={cocktailImage}
            className="img-fluid rounded"
            alt="cocktail"
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          <button
            onClick={handleClick}
            type="button"
            class="btn btn-outline-success btn-lg btn-block"
          >
            Donate Here!
          </button>
        </Col>
      </Row>
    </>
  );
};

export default SupportUs;
