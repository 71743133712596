import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import DrinkSelectionTableRow from "./DrinkSelectionTableRow";

const DrinkSelectionTable = ({ drinks, onSelection, status }) => {
  const [selected, setSelected] = useState([]);
  const [drinkStatus, setDrinkStatus] = useState([]);

  useEffect(() => {
    let tmp = [];
    tmp = drinks.map((drink) => {
      const val = status.find((s) => s.id === drink.id);
      if (val) {
        return "success";
      } else if (val === false) {
        return "error";
      } else {
        return "";
      }
    });

    setDrinkStatus(tmp);
  }, [status, drinks]);

  const handleDrinkSelection = (drinkId) => {
    let updated = [...selected];
    const i = updated.indexOf(drinkId);
    if (i > -1) {
      updated.splice(i, 1);
    } else {
      updated.push(drinkId);
    }

    setSelected(updated);
    onSelection(updated);
  };

  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Source</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {drinks.map((drink, index) => (
          <DrinkSelectionTableRow
            key={drink.id}
            drink={drink}
            onSelection={handleDrinkSelection}
            status={drinkStatus[index]}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default DrinkSelectionTable;
