import "./index.css";
import NavigationBar from "../header/header";
import UserContentContext from "../context/user-data-context";
import { useState } from "react";
import AuthWrapper from "../auth-wrapper";
import Container from "react-bootstrap/Container";
import Footer from "../footer";
import AppRoutes from "../app-routes";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router } from "react-router-dom";
import configData from "../config.json";
import { useNavigate } from "react-router-dom";

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

function App() {
  const [userContent, setUserContent] = useState(null);

  document.title = "mixirs";

  return (
    <Router>
      <Auth0ProviderWithRedirectCallback
        domain={configData.AUTH0_DOMAIN}
        clientId={configData.AUTH0_CLIENTID}
        redirectUri={window.location.origin}
        audience={configData.AUTH0_AUDIENCE}
        scope="openid profile email read:current_user"
        useRefreshTokens={true}
        cacheLocation="memory"
      >
        <UserContentContext.Provider value={{ userContent, setUserContent }}>
          <Container fluid>
            <AuthWrapper />
            <NavigationBar />
            <AppRoutes />
            <Footer />
          </Container>
        </UserContentContext.Provider>
      </Auth0ProviderWithRedirectCallback>
    </Router>
  );
}

export default App;
