import { useNavigate, useParams } from "react-router-dom";
import UserContentContext from "../context/user-data-context";
import useUserRole from "../hooks/useUserRole";
import { useState, useEffect, useContext } from "react";
import FetchIngredient from "../clients/FetchIngredient";
import Roles from "../common/Roles";
import PutIngredient from "../clients/PutIngredient";
import IngredientForm from "./components/IngredientForm";
import DeleteButton from "./components/DeleteButton";
import ErrorPage from "../error-page";
import DeleteIngredient from "../clients/DeleteIngredient";

const EditIngredient = () => {
  const userRole = useUserRole();
  const { id } = useParams();
  const [ingredient, setIngredient] = useState();
  const navigate = useNavigate();
  const { userContent } = useContext(UserContentContext);

  useEffect(() => {
    if (userRole !== Roles.admin) {
      return;
    }
    const doFetch = async () => {
      const fetchIngredient = await FetchIngredient(id);
      console.log("fetchIngredient", fetchIngredient);

      setIngredient(fetchIngredient);
    };

    doFetch();
  }, [id, userRole]);

  const handleSubmit = async (ingredient) => {
    console.debug('onSubmit', ingredient);
    try {
      const response = await PutIngredient(ingredient, userContent.accessToken);
      console.debug(response);
      if (!response.ok) {
        alert("error");
        console.error(response);
      } else {
        alert("changes submitted");
      }
    } catch (error) {
      alert("error");
      console.error(error);
    }
  };

  const handleDelete = async () => {
    const response = await DeleteIngredient(
      ingredient.id,
      userContent.accessToken
    );
    if (response.ok) {
      alert("ingredient deleted");
      navigate("/admin");
    } else {
      alert("error");
    }
  };

  if ((userRole ?? Roles.unknown) !== Roles.admin) {
    console.debug("User is - ", userRole);
    return <ErrorPage />;
  }

  return (
    <div>
      <h2 className="mb-4">Edit Ingredient</h2>
      <IngredientForm
        ingredient={ingredient}
        onSubmit={handleSubmit}
      />
      <div className="mt-4">
        <DeleteButton
          ingredient={ingredient}
          onDeleteConfirm={handleDelete}
        />
      </div>
    </div>
  );
};

export default EditIngredient;
