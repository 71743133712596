const BookmarkDrink = async (drinkId, action, userContent) => {
  const url = `${process.env.REACT_APP_MIXIRS_API}/api/favorites/${userContent.favorites}`;
  const request = {
    method: "patch",
    withCredentials: true,
    headers: new Headers({
      Authorization: `Bearer ${userContent?.accessToken}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      action: action,
      drinkId: drinkId,
    }),
  };

  const response = await fetch(url, request);
  console.debug("BookmarkDrink", response);
  return response;
};

export default BookmarkDrink;
