import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import FeaturedDrinks from "./featured-drinks";
import IngredientAddRemoveButton from "../ingredient-add-remove-button";
import { FetchBar } from "../clients/FetchUserData";
import UserContentContext from "../context/user-data-context";
import EditIngredientButton from "../edit-ingredient-button";

const IngredientDetail = () => {
  const { id } = useParams();
  const [ingredient, setIngredient] = useState();
  const [isLoaded, setIsLoaded] = useState();
  const { userContent } = useContext(UserContentContext);
  const [isAdded, setIsAdded] = useState(false);

  document.title = `mixirs | ${ingredient?.name}`;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_MIXIRS_API}/api/ingredients/${id}`)
      .then((res) => res.json())
      .then(
        (data) => {
          setIngredient(data);
          setIsLoaded(true);
        },
        (error) => {
          console.log(error);
          setIsLoaded(true);
        }
      );
  }, [id]);

  useEffect(() => {
    if (!userContent) return;

    const getBar = async () => {
      const bar = await FetchBar(userContent.accessToken, userContent.bar);
      const hasIngredient = bar.availableIngredients.includes(
        (x) => x.id === id
      );
      setIsAdded(hasIngredient);
    };

    getBar();
  }, [userContent, id]);

  if (isLoaded && ingredient) {
    return (
      <div>
        <div className="container">
          <Row>
            <Col>
              <span className="h1">{ingredient.name}</span>
              <Badge pill bg="primary" text="white" className="ml-2">
                {ingredient?.baseType}
              </Badge>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="h6 text-muted">
                Type: {ingredient.ingredientType}
              </span>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <IngredientAddRemoveButton
                ingredientId={ingredient.id}
                isAdded={isAdded}
              />
              <EditIngredientButton ingredient={ingredient} />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>{ingredient.description}</p>
            </Col>
          </Row>
          <Row>
            {ingredient.tags.map((tag, idx) => (
              <Badge pill bg="primary" text="white" key={idx} className="mr-2">
                {tag}
              </Badge>
            ))}
          </Row>
        </div>
        <div className="mt-4">
          <FeaturedDrinks
            ingredientId={ingredient.id}
            ingredientName={ingredient.name}
          />
        </div>
      </div>
    );
  }

  return <div>Loading...</div>;
};

export default IngredientDetail;
